var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"Variant Colors"}}),_c('page-header',{attrs:{"back":{ to: { name: 'cars.index' }, text: _vm.trans('Cars') }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.trans(((_vm.variant ? _vm.variant.name : '') + " Variant Colors")))}})]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"primary","exact":"","to":{ name: 'cars.variant-colors.create' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus-circle")]),_c('span',{domProps:{"textContent":_vm._s('Add new')}})],1)]},proxy:true}])}),_c('v-card',[_c('toolbar-menu',{ref:"toolbar",attrs:{"items":_vm.tabletoolbar},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.cars.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"items-per-page":5,"color":"primary","item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.color.code))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.update_at)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPrice(item.price)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == true)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" active ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" inactive ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","to":{
                    name: 'cars.variant-colors.edit',
                    params: { variantcolorid: item.id, id: item.variant_id },
                  }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Edit')}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.handleDeleteVariantColor(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Delete')}})])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }